import Vue from 'vue'
import App from './App.vue'
import router from './route'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VeeValidate, { Validator } from "vee-validate";
import pt from "vee-validate/dist/locale/pt_PT"
import moment from "moment"
import VueTelInput from 'vue-tel-input'
import money from 'v-money'

import i18n from './plugins/i18n'
import filters from './filters'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//Install Libraries
moment.locale("pt_PT")
Vue.use(VeeValidate)
Validator.localize("pt_PT", pt)
Vue.use(VueAxios, axios)
axios.defaults.baseURL = "https://backoffice.gpo.positiva.co.ao/api/merchants";
axios.defaults.headers.post["Content-Type"] = "application/json";

// Install BootstrapVue
Vue.use(BootstrapVue)

// Install BootstrapVue icon
Vue.use(IconsPlugin)
//Install VueTel
Vue.use(VueTelInput)
Vue.use(money, {precision: 4})

Vue.config.productionTip = false
filters(Vue)

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
