<template>
  <div id="app">
    <Views/>
  </div>
</template>

<script>
import Views from './views/Views.vue'
import '@/assets/css/style.css'

export default {
  name: 'App',
  components: {
    Views
  }
}
</script>

<style >
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.input_error{
  border: 1px solid red !important;
}

.error-box{
  height: 20px;
  width: 100%;
}

label{
  font-size: 14px;
  margin-bottom: 0 !important;
}

.vti__input{
  height: 48px;
  font-size: 14px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.vue-tel-input{
  line-height: 30px;
  border-radius: 10px !important;
  border: 2px solid #8cbee8 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.vti__dropdown:hover{
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.vti__dropdown-list.above{
  display: none !important;
}
.vti__dropdown-list.below{
  display: none !important;
}

/* ---------------------------------------------------
    CSS for loading dots
----------------------------------------------------- */
 @keyframes dot-keyframes {
	0% {
    opacity: 0.4;
    transform: scale(1, 1);
	}
	50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
	}
	100% {
    opacity: 0.4;
    transform: scale(1, 1);
	}
}

.loading-dots {
  text-align: center;
  width: 100%;
}

.loading-dots--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #fff;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.loading-dots--dot:nth-child(2) {
	animation-delay: 0.5s;
}

.loading-dots--dot:nth-child(3) {
	animation-delay: 1s;
}
 
</style>
