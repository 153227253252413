<template>
  <div>
    <header class="header" :class="scrollPosition < 50 ? 'transparent' : 'header__light'">
      <div class="header-logo__call-center">
        <router-link to="/">
          <img src="../assets/vPOS-beta.svg" alt="Positiva GPO Logo" />
        </router-link>
        <li class="call-center d-flex justify-content-center ml-sm-3" :class="{'light': bgr === 'light'}">
          <span>{{ $t('header.hours.hour') }}</span>
          <span>{{ $t('header.hours.callcenter') }}</span>
        </li>
      </div>
      <nav class="nav_vpos">
        <li v-if="home">
          <router-link tag="a" to="/">
            <button class="header-menu" :class="{'light': bgr === 'light'}">{{ $t('header.home') }}</button>
          </router-link>
        </li>
        <li v-if="dev">
          <a
            href="https://developer.gpo.positiva.co.ao/?shell#introduction"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="header-menu" :class="{'light': bgr === 'light'}">{{ $t('header.dev') }}</button>
          </a>
        </li>
        <li v-if="pricing">
          <router-link tag="a" to="/prices">
            <button class="header-menu" :class="{'light': bgr === 'light'}">{{ $t('header.prices') }}</button>
          </router-link>
        </li>
        <li v-if="signin">
          <a href="https://merchant.gpo.positiva.co.ao" target="_blank" rel="noopener noreferrer">
            <button class="header-menu" :class="{'light': bgr === 'light'}">{{ $t('header.signin') }}</button>
          </a>
        </li>
        <li v-if="signup">
          <router-link to="/register">
            <button class="btn-signup-create">{{ $t('header.signup') }}</button>
          </router-link>
        </li>
        <li class="d-flex justify-content-center">
          <menu-language v-if="lang" :bottom="true" display="text"></menu-language>
        </li>
      </nav>
      <div class="menu" id="nav-container">
        <div class="bg"></div>
        <div class="button" tabindex="0">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
        <div id="nav-content" tabindex="0">
          <ul>
            <li>
              <router-link to="/">
                <img src="../assets/vPOS-beta.svg" alt="vPOS Logo" />
              </router-link>
            </li>
            <li v-if="home">
                <router-link tag="a" to="/">
                    <button class="header-menu-nav">{{ $t('header.home') }}</button>
                </router-link>
            </li>
            <li v-if="dev">
              <a
                href="https://developer.vpos.ao/?shell#introduction"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button class="header-menu-nav">{{ $t('header.dev') }}</button>
              </a>
            </li>
            <li v-if="pricing">
              <router-link tag="a" to="/prices">
                <button class="header-menu-nav">{{ $t('header.prices') }}</button>
              </router-link>
            </li>
            <li v-if="signin">
              <a href="https://merchant.vpos.ao" target="_blank" rel="noopener noreferrer">
                <button class="header-menu-nav">{{ $t('header.signin') }}</button>
              </a>
            </li>
            <li v-if="signup">
              <router-link class="btn_letter" to="/register">
                <button class="btn_nav_lateral_menu_register">{{ $t('header.signup') }}</button>
              </router-link>
            </li>
            <li class="call_center_lateral_menu">
              <span>{{ $t('header.hours.hour') }}</span>
              <span>{{ $t('header.hours.callcenter') }}</span>
            </li>
            <li class="espaco"></li>

            <li>
              <p
                class="description"
              >© {{new Date().getFullYear()}} vPOS - {{ $t('footer.allrights') }}</p>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import MenuLanguage from '@/components/MenuLanguage.vue'

export default {
    components: {
      'menu-language': MenuLanguage
    },
    props: {
        bgr: {
            type: String,
            default: 'dark'
        },
        home: {
            type: Boolean,
            default: true
        },
        dev: {
            type: Boolean,
            default: true
        },
        pricing: {
            type: Boolean,
            default: true
        },
        signin: {
            type: Boolean,
            default: true
        },
        signup: {
            type: Boolean,
            default: true
        },
        lang: {
          type: Boolean,
          default: true
        }
    },
    data() {
      return {
        scrollPosition: null
      }
    },
    methods: {
      updateScroll () {
        this.scrollPosition = window.scrollY
      }
    },
    mounted () {
      window.addEventListener('scroll', this.updateScroll)
    }
}
</script>
<style lang="scss" scoped>
/**Header vPOS */
nav,
ul {
  list-style: none;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  z-index: 999;
}
header nav {
    display: flex;
}
header li {
    margin: 0 15px;
}
header li:first-child {
    margin-left: 0;
}
header li:last-child {
    margin-left: 0;
    margin-right: 0;
}
.header-menu {
    background: #2699fb00 0% 0% no-repeat padding-box;
    border: transparent;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    opacity: 1;
    padding: 7px;
}

.header__light{
  position: fixed;
  background-color: #ffffff;
}

.header__light .btn-signup-create{
  color: #ffffff !important;
}

.header__light span,
.header__light a,
.header__light button{
  color: #1363A5 !important;
}

.light{
    color: #1363A5 !important;
}

.header-logo__call-center{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header-logo__call-center .call-center span{
  font-size: .8rem;
}

.btn_entrar {
    background: #2699fb00 0% 0% no-repeat padding-box;
    border: 2px solid #f1f9ff;
    border-radius: 8px;
    opacity: 1;
    padding: 7px 35px;
    color: #ffffff;
}
.btn_registar {
    background: #d05d1c 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    border: none;
    padding: 9px 35px;
    color: #ffffff;
}
.btn_letter {
    color: #ffffff;
    text-decoration: none;
}
a button {
    cursor: pointer;
}
.call-center {
    display: flex;
    flex-direction: column;
    color: #1363A5;
}

.btn-signup-create {
    width: 130px;
    height: 40px;
    background: #d05d1c 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    border: none;
    color: #ffffff;
}

@media (max-width: 700px) {
    .call-center,
    .header-menu,
    .btn-signup-create {
      display: none;
      margin-top: 5px;
    }

    .header{
      padding: 20px;
    }
}

.call-center,
.header-menu {
  color: #ffffff;
}


/**menu lateral start */
.menu {
    display: none;
}

@media only screen and (min-width: 320px) and (max-width: 700px) and (orientation: portrait) {
    header nav {
        display: flex;
        margin-left: -10px;
    }
    .menu {
      display: block;
    }
    #nav-container .bg {
      position: fixed;
      top: 70px;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      background: #000;
      z-index: 3;
    }
    #nav-container:focus-within .bg {
      visibility: visible;
      opacity: 0.6;
    }
    #nav-container * {
      visibility: visible;
    }
    .button {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      border-radius: 0;
      height: 70px;
      width: 30px;
      cursor: pointer;
      pointer-events: auto;
      margin-left: 25px;
      touch-action: manipulation;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .icon-bar {
      display: block;
      width: 100%;
      height: 3px;
      background: #d05d1c;
      transition: 0.3s;
    }
    .icon-bar + .icon-bar {
      margin-top: 5px;
    }
  
    #nav-container:focus-within .button {
      pointer-events: none;
    }
    #nav-container:focus-within .icon-bar:nth-of-type(1) {
      transform: translate3d(0, 8px, 0) rotate(45deg);
    }
    #nav-container:focus-within .icon-bar:nth-of-type(2) {
      opacity: 0;
    }
    #nav-container:focus-within .icon-bar:nth-of-type(3) {
      transform: translate3d(0, -8px, 0) rotate(-45deg);
    }
    #nav-content {
      height: 100vh;
      padding: 20px;
      max-width: 330px;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      pointer-events: auto;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transform: translateX(-100%);
      transition: transform 0.3s;
      z-index: 20;
    }
  
    #nav-content ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    #nav-content li a {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      transition: color 0.1s;
    }
    #nav-content li a:hover {
      text-decoration: none;
    }
  
    #nav-content li:not(.small) + .small {
      margin-top: auto;
    }
  
    #nav-container:focus-within #nav-content {
      transform: none;
    }
    .btn_nav_lateral_menu_register {
      background: #d05d1c 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      border: none;
      cursor: pointer;
      padding: 13px 95px;
      color: #ffffff;
      margin-top: 25px;
    }
    .header-menu-nav {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 8px;
      opacity: 1;
      color: #d05d1c;
      margin-top: 15px;
    }
    .btn_letter_lateral_menu {
      color: #d05d1c;
    }
    .call_center_lateral_menu {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      text-align: center;
      color: #0073ab;
      margin-top: 15px;
    }
    .icon_lateral_menu {
      display: flex;
    }
    .description {
      text-align: center;
      color: #0073ab;
    }
    .espaco {
      margin-top: 350px;
    }
}
@media (max-width: 375px) {
    #nav-content {
        max-width: 280px;
    }
    .btn_nav_lateral_menu_register {
        padding: 7px 90px;
    }
    .header-menu-nav {
        // padding: 5px 95px;
    }
    .espaco {
        margin-top: 300px;
    }
}
@media (width: 320px) {
    #nav-content {
        max-width: 230px;
    }
    .btn_nav_lateral_menu_register {
        padding: 7px 60px;
    }
    .header-menu-nav {
        padding: 5px 65px;
    }
    .espaco {
        margin-top: 200px;
    }
}
/**menu lateral end */
</style>