<template>
  <div class="container-vpos">
    <div class="header_vpos">
      <div class="inside_vpos">
        <vpos-header :home="false"/>
        <!--Carousel Fade start-->
        <div class="carrousel-center">
          <b-carousel
            id="carousel-no-animation"
            style="text-shadow: 0px 0px 2px #000;"
            no-animation
            indicators
            img-width="1024"
            img-height="480"
          >
            <b-carousel-slide img-blank>
              <div class="carrousel-element">
                <h1>{{ $t('carrousel.firstPhrase') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-blank>
              <div class="carrousel-element">
                <h1>{{ $t('carrousel.secondPhrase') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-blank>
              <div class="carrousel-element">
                <h1>{{ $t('carrousel.thirdPhrase') }}</h1>
                  </div>
                </b-carousel-slide>
          </b-carousel>
        </div>
        <!--Crousel Fade end-->
      </div>
    </div>
    <div class="what_is_vPOS">
      <div class="details_vpos">
        <h2>{{ $t('whatIsVpos.whatIs') }}</h2>
        <img
          class="img_mockup_vpos mt-2 mb-5"
          src="../assets/app-mockup.png"
          alt="App Mockup"
        />
        <blockquote class="description_vpos">
          {{ $t('whatIsVpos.quote') }}
        </blockquote>
      </div>
    </div>
    <div class="receive-payment">
      <h5 v-html="$t('whatIsVpos.receivePayment')"></h5>
        <img
          class="multicaixa_express"
          src="../assets/vPOS-logo-white.png"
          alt="Multicaixa Express"
        />
      <div class="stores_vpos d-flex flex-row justify-content-between">
        <div>
          <img v-if="atualLanguage === 'yes'" class="ml-2 mt-3" src="../assets/app-store-en-soon.png" alt=""/>
          <img v-if="atualLanguage === 'no'" class="ml-2 mt-3" src="../assets/app-store-coming.png" alt=""/>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=ao.co.nextbss.proxypay_pos" target="_blank"><img v-if="atualLanguage === 'yes'" class="ml-2 mt-3" src="../assets/google-play-en.png" alt=""/></a>
          <a href="https://play.google.com/store/apps/details?id=ao.co.nextbss.proxypay_pos" target="_blank"><img v-if="atualLanguage === 'no'" class="mt-3" src="../assets/google-play.png" alt=""/></a> 
        </div>
      </div>
    </div>
    <div class="integration-api d-flex flex-column align-items-center justify-center">
      <h5 class="mb-4" v-html="$t('integrations.text')"></h5>

        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide>
            <div class="snippet">
              <vpos-tab
                :tabs="tabs"
                :currentTab="currentTab"
                @onClick="handleClick"
                :tabIcon="true"
                firsTabElement="cURL"
              />
              <div class="content">
                <div v-if="currentTab === 'cURL'">
                  <codemirror v-model="curlCode" :options="cURLOptions" />
                </div>
                <div v-if="currentTab === 'js'">
                  <codemirror v-model="jsCode" :options="jsOptions" />
                </div>
                <div v-if="currentTab === 'php'">
                  <codemirror v-model="phpCode" :options="phpOptions" />
                </div>
                <div v-if="currentTab === 'rb'">
                  <codemirror v-model="rbCode" :options="rbOptions" />
                </div>
                <div v-if="currentTab === 'py'">
                  <codemirror v-model="pyCode" :options="pyOptions" />
                </div>
                <div v-if="currentTab === 'java'">
                  <codemirror v-model="javaCode" :options="javaOptions" />
                </div>
                <div v-if="currentTab === 'csharp'">
                  <codemirror v-model="csharpCode" :options="csharpOptions" />
                </div>
              </div>
        </div>
        </swiper-slide>
        <swiper-slide><img class="imgs-plugins" src="../assets/plugins.jpeg" alt=""></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="d-flex flex-row mt-5">
        <a href="https://developer.vpos.ao/?shell#introductiono" target="_blank" rel="noopener noreferrer">
          <button class="btn_integration">{{ $t('integrations.btn') }}</button>
        </a>
      </div>
    </div>
    <div class="merchant">
      <div class="merchant_vpos">
        <h2>{{ $t('merchant.merchant') }}</h2>
        <p class="description_merchant">{{ $t('merchant.description') }}</p>
      </div>
      <div class="merchant_items d-flex ">
        <div class="items_detail">
          <img src="../assets/store.svg" alt="Store" />
          <div class="number-circle text-white border-0 rounded-circle d-flex justify-content-center align-items-center position-absolute">1</div>
          <span class="items_title">{{ $t('merchant.icons.firstIcon') }}</span>
        </div>
        <div class="items_detail">
          <img src="../assets/bank.svg" alt="Bank" />
          <div class="number-circle text-white border-0 rounded-circle d-flex justify-content-center align-items-center position-absolute">2</div>
          <span class="items_title">{{ $t('merchant.icons.secondIcon') }}</span>
        </div>
        <div class="items_detail">
          <img src="../assets/phone.svg" alt="Phone" />
          <div class="number-circle text-white border-0 rounded-circle mr-4 d-flex justify-content-center align-items-center position-absolute">3</div>
          <span class="items_title">{{ $t('merchant.icons.thirdIcon') }}</span>
        </div>
      </div>
      <div class="merchant_register">
        <router-link class="btn_letter" to="/register">
          <button class="btn_merchant_register">{{ $t('merchant.icons.btn') }}</button>
        </router-link>
      </div>
    </div>
    <div class="payment">
      <h5>{{ $t('acceptPayment') }}</h5>
        <img
          class="multicaixa_express"
          src="../assets/multicaixa-express.png"
          alt="Multicaixa Express"
        />
      <div class="stores_vpos d-flex flex-row justify-content-between">
        <a href="https://apps.apple.com/us/app/multicaixa-express/id1433675921" target="_blank" rel="noopener noreferrer">
          <img v-if="atualLanguage === 'yes'" class="ml-2 mt-3" src="../assets/app-store-en.png" alt=""/>
          <img v-if="atualLanguage === 'no'" class="ml-2 mt-3" src="../assets/app-store.svg" alt=""/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.sibsint.mcxwallet&hl=pt&gl=US" target="_blank" rel="noopener noreferrer">
          <img v-if="atualLanguage === 'yes'" class="ml-2 mt-3" src="../assets/google-play-en.png" alt="" />
          <img v-if="atualLanguage === 'no'" class="ml-2 mt-3" src="../assets/google-play.png" alt="" />
        </a>
      </div>
    </div>
    <vpos-footer/>
  </div>
</template>

<script>
import vPosHeader from '@/components/vPosHeader.vue'
import vPosFooter from '@/components/vPosFooter.vue'
import vPosTab from '@/components/vPosTab.vue'

import { codemirror } from 'vue-codemirror'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

import 'codemirror/lib/codemirror.css'

// import languages
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/ruby/ruby.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/clike/clike'

// import theme style
import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/dracula.css'

export default {
  components: {
    'vpos-header': vPosHeader,
    'vpos-footer': vPosFooter,
    'vpos-tab': vPosTab,
    Swiper,
    SwiperSlide,
    codemirror
  },
  data() {
    return {
      phpCodes: "use Vpos\\Vpos\\Vpos;",
      tabs: [
        { title: 'js.png', value: 'js' },
        { title: 'php.png', value: 'php' },
        { title: 'rb.png', value: 'rb', },
        { title: 'py.png', value: 'py', },
        { title: 'java.png', value: 'java', },
        { title: 'csharp.png', value: 'csharp', },
      ],
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      currentTab: 'cURL',
      curlCode: `
curl -i -XPOST \\
  -H "Content-Type: application/json" \\
  -H "Idempotency-Key: 06a95bd8-2d16-11eb-adc1-0242ac120002" \\
  -H "Authorization: Bearer hvHCkChzXuOdMonpeHFxiDEVTfSR9QK5BW4nz6e4XYwWoKsZZjp" \\
  "http://<host>/api/v1/transactions" \\
  -d '{"type": "payment", "pos_id": 123, "mobile": "900111222", "amount": "123.45",
    "callback_url": "https://your_hostname/link/to/confirm"}'
      `,
      jsCode: `
  const Vpos = require('vpos');

  let merchant = new Vpos(token: 'your_token_here', environment: 'PRD');

  async function payment() {
    return merchant.newPaymentTransaction({amount: "123.45", customer: "900111222"});
  }

  payment();
      `,
      phpCode: `
  use Vpos\\Vpos\\Vpos;

  $token = "YOUR VPOS TOKEN";
  $pos_id = "YOUR GPO POS ID";
  $payment_callback_url = "YOUR PAYMENT CALLBACK URL";
  $refund_callback_url = "YOUR REFUND CALLBACK URL";
  $supervisor_card = "YOUR GPO SUPERVISOR CARD";


  $merchant = new Vpos($token, $pos_id, $payment_callback_url, $refund_callback_url, $supervisor_card);

  $payment = $merchant->newPayment(customer: "900111222", amount: "123.45");
      `,
      rbCode: `
  require 'vpos'

  merchant = Vpos.new(token: 'your_token_here', environment: 'PRD')

  payment = merchant.new_payment("900111222", "123.45")
      `,
      pyCode: `
  import vpos

  merchant = Vpos(token='your_token_here', environment='PRD')

  payment = merchant.new_payment("900111222", "123.45");
      `,
      javaCode: `
  var merchant = new Vpos('your_token_here');

  var payment = merchant.newPayment("900111222", "123.45");
      `,
      csharpCode: `
  Vpos merchant = new Vpos();

  Response locationResponse = merchant.NewPayment("900111222", "123.45");
      `,
      cmOptions: {
        tabSize: 0,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        styleSelectedText: true,
        theme: "dracula",
        matchBrackets: true,
        readOnly: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Ctrl": "autocomplete" },
        hintOptions:{
          completeSingle: false
        }
      },
      cURLCmOptions: {
        tabSize: 0,
        styleActiveLine: false,
        lineNumbers: false,
        line: false,
        foldGutter: true,
        styleSelectedText: true,
        theme: "dracula",
        matchBrackets: true,
        readOnly: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Ctrl": "autocomplete" },
        hintOptions:{
          completeSingle: false
        }
      },
      // swiperOption: {
      //   direction: 'vertical',
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true,
      //     // dynamicBullets: true
      //   }
      // }
    }
  },
  computed: {
    atualLanguage () {
      return this.$t('mobileApps.en')
    },
    codemirror() {
      return this.$refs.cmEditor.codemirror
    },
    cURLOptions () {
      return { ...this.cURLCmOptions, ...{ mode: 'text/x-php'} }
    },
    jsOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/javascript'} }
    },
    phpOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/x-php'} }
    },
    rbOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/x-ruby'} }
    },
    pyOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/x-cython'} }
    },
    javaOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/x-java'} }
    },
    csharpOptions () {
      return { ...this.cmOptions, ...{ mode: 'text/x-csharp'} }
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    }
  },
  directives: {
    swiper: directive
  },
  mounted () {
    console.log('Current Swiper instance object', this.swiper)
    this.swiper.slideTo(3, 10, true)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.number-circle{
  width: 40px;
  height: 40px;
  margin-top: 100px;
  right: 0;
  font-weight: 600;
  background-color: #06b45d;
}

@media (max-width: 700px) {
  .call-center{
    display: none;
  }
  .img_mockup_vpos {
    width: 300px !important;
    margin-bottom: 15px;
  }
  .carrousel-element {
    margin: 5px 0px !important;
  }
  h1 {
    font-size: 1.2em;
    font-weight: bold;
  }
  .merchant_items {
    flex-direction: column;
    align-items: center;
  }
  .items_detail {
    margin: 25px;
  }
}
@media (max-width: 320px) {
  .carrousel-element {
    margin: 5px 0px !important;
  }
  h1 {
    font-size: 1em;
    font-weight: bold;
  }
  .btn_carrousel_register {
    padding: 6px 30px !important;
    margin-top: 0px !important;
  }
}
@media (width: 360px) {
  .carrousel-element {
    margin: 0px !important;
  }
}

.stores_vpos{
  width: 285px;
}

// .stores_vpos img{
//   width: 200px;
//   height: 60px;
// }

/**CAROUSEL */
.carrousel-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
h1 {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #0000007e;
  opacity: 1;
}
.carrousel-element {
  margin: 140px 0px;
}
@media only screen and (min-width: 768px) and (max-width: 900px) and (orientation: portrait) {
  .carrousel-element {
    margin: 10px 0px;
  }
}
.btn_carrousel_register {
  background: #d05d1c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border: none;
  cursor: pointer;
  padding: 9px 40px;
  color: #ffffff;
  margin-top: 15px;
  // box-shadow: 0px 5px 7px 0px hsl(0deg 0% 9%);
}

.btn_integration{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d05d1c;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  padding: 9px 30px;
  color: #d05d1c;
}

.btn_integration:hover{
  background: #d05d1c 0% 0% no-repeat padding-box;
  color: #ffffff;
  opacity: 0.8;
}

/**What is vPOS */
.what_is_vPOS {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.details_vpos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}
h2 {
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  color: #1363a5;
  opacity: 1;
}
.img_mockup_vpos {
  width: 400px;
}
.description_vpos {
  text-align: center;
  font-size: 1.2rem;
  max-width: 600px;
  letter-spacing: 0px;
  color: #aaaaaa;
  opacity: 1;
  line-height: 0.5;
}

@media (min-width: 768px) {
  .description_vpos {
    font-size: 1.8rem;
  }
}
blockquote {
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #5a5a5a;
  content: open-quote;
  font-size: 6em;
  line-height: 0.1em;
  vertical-align: -0.4em;
  opacity: 0.3;
}
/**Receive-payment */
.receive-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}
/**Merchant */
.merchant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f1f9ff;
  padding: 50px 0px;
}
.merchant_vpos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.description_merchant {
  max-width: 350px;
  text-align: center;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.merchant_items {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}
.items_detail {
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 165px;
  position: relative;
}
.items_title {
  text-align: center;
  margin-top: 10px;
  letter-spacing: 0px;
  color: #5a5a5a;
  font-weight: 600;
  opacity: 1;
}
.merchant_register {
  display: flex;
  justify-content: center;
}
.btn_merchant_register {
  background: #d05d1c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border: none;
  cursor: pointer;
  padding: 9px 30px;
  color: #ffffff;
  // box-shadow: 1px 4px 7px 0px hsl(0deg 1% 82%);
}

@media (min-width: 768px) {
  .btn_merchant_register {
    // margin-left: 2.5rem;
  }
}
/**Integration */
.integration {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
}
.integration_up,
.integration_down {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 100px;
}
.integration_description {
  text-align: left;
  max-width: 500px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.9;
}
.integration_image {
  width: 572px;
}
@media (min-width: 700px) {
  .items_detail{
    display: flex;
    flex-direction: column;
  } 
}
@media (width: 320px) {
  .integration_image {
    width: 320px !important;
  }
  .integration_up,
  .integration_down {
    margin: 0px;
  }
}
@media (width: 360px) {
  .integration_image {
    width: 270px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1136px) and (orientation: portrait) {
  .integration_image {
    width: 370px !important;
  }
  .integration_up,
  .integration_down {
    margin: 0px 30px;
  }
}
@media (width: 1024px) {
  .integration_image {
    width: 400px !important;
  }
  .integration_description {
    max-width: 400px;
  }
  .integration_up,
  .integration_down {
    margin: 0px 90px;
  }
}
@media (max-width: 700px) {
  .integration_up {
    flex-direction: column;
  }
  .integration_down {
    flex-direction: column-reverse;
  }
  .integration_description {
    text-align: center;
    padding: 5px;
  }
  .integration_image {
    width: 370px;
  }
  .integration_up,
  .integration_down {
    margin: 0px;
  }
}
/**Payment */
.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 50px 0px;
}
h4,
h5 {
  text-align: center;
  letter-spacing: 0px;
  font-weight: bold;
  color: #1363a5;
  opacity: 1;
}
.multicaixa_express {
  width: 102px;
  margin: 10px 0px;
}

@media (max-width: 700px) {
  .code-carbon{
    max-width: 300px;
  }
}
.google_play_coming {
  margin-left: 5px;
}
/**menu lateral end */

.integration-api{
  background-color: rgba(184,180,180,.06);
  padding: 50px;
}

@media (max-width: 545px) {
  .integration-api{
    padding: 20px;
  }
}

/*External Tabs*/
.tabs {
  position: relative;
  margin: 0 auto;

  &__active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: black;
    transition: transform 0.4s ease, width 0.4s ease;
  }

  &__item {
    display: inline-block;
    margin: 0 5px;
    padding: 10px;
    padding-bottom: 8px;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: gray;
    text-decoration: none;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.25s;

    &_active {
      color: black;
    }

    &:hover {
      border-bottom: 2px solid gray;
      color: black;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid gray;
      color: black;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
