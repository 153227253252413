/*
 *  This filters function will be accessed from the
 *  root main.js of the project to initialize it
 *  passing a the Vue instance object as an argument
 */
export default Vue => {

  /*
   *   Convert to kwanza
   */
  Vue.filter('toKwanza', (value) => {
    value = parseFloat(value)
    return (value.toLocaleString('ao-PT').indexOf('.') > -1) ? value.toLocaleString('ao-PT') + ' AOA' : value.toLocaleString('ao-PT') + '.00' + ' AOA'
  })

  /*
   *  Replace coma with point
   */
  Vue.filter('replaceComa', (value) => {
    value = value.toString()
    return value.replace(/[.,]/g, function (x) {
      return x === ',' ? '.' : ','
    })
  })

  /*
   *  Replace point with coma
   */
  Vue.filter('replacePoint', (value) => {
    return value ? value.replace('.', ',') : 0
  })

}
