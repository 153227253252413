import Vue from 'vue'
import Router from 'vue-router'

import Landing from './views/Landing'
import Register from './views/Register'
import Prices from './views/Prices'
import View from './views/Views'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
      {
      path: '/',
      component: View,
      children: [
        {
          path:'/',
          name: Landing,
          component: Landing
        },
        {
          path:'/register',
          name: Register,
          component: Register
        },
        {
          path:'/prices',
          name: Prices,
          component: Prices
        }
      ]
    } 
  ]
})