<template>
    <div class="container-language" :class="{ 'bottom' : bottom, 'ml-3' : classText }" v-click-outside="hide" >
      <div class="language-choose" @click="showBobbly = !showBobbly" >
        <img v-if="langText === 'PT'" src="../assets/menu-lang/ao-mini-flag.png" alt="Angola Flag">
        <img v-if="langText === 'EN'" src="../assets/menu-lang/us-mini-flag.png" alt="Angola Flag">
      </div>
      <div class="speech-bubble" :class="{ 'show-bobbly' : !showBobbly}" >
        <ul>
          <li @click.prevent="handleLocale('pt')" v-if="langText === 'EN'" class="lang-row">
            <img v-if="display === 'flag' && langText === 'EN'" src="../assets/menu-lang/ao-mini-flag.png" alt="Angola Flag">
            <span v-if="display === 'text' && langText === 'EN'" class="lang-title">Português</span>
          </li>
          <li @click.prevent="handleLocale('en')" v-if="langText === 'PT'" class="lang-row">
            <img v-if="display === 'flag' && langText === 'PT'" src="../assets/menu-lang/us-mini-flag.png" alt="USA Flag">
            <span v-if="display === 'text' && langText === 'PT'" class="lang-title">English</span>
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import { Validator } from 'vee-validate'
import pt from 'vee-validate/dist/locale/pt_PT'
import en from 'vee-validate/dist/locale/en'

export default {
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: 'text'
    },
    classText: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      showBobbly: true,
      langDefaultText: '',
      langText: ''
    }
  },
  methods: {
    handleLocale (locale) {
      window.localStorage.setItem('currentLanguage', locale)
      this.$i18n.locale = locale
      this.langDefaultText = locale
      this.hide()
    },
    hide () {
      this.showBobbly = true
    },
    getBrowserLocales (options = {}) {
      const defaultOptions = {
        languageCodeOnly: false
      }

      const opt = {
        ...defaultOptions,
        ...options
      }

      const browserLocales =
        navigator.languages === undefined
          ? [navigator.language]
          : navigator.languages

      if (!browserLocales) {
        return undefined
      }

      return browserLocales.map(locale => {
        const trimmedLocale = locale.trim()

        return opt.languageCodeOnly
          ? trimmedLocale.split(/-|_/)[0]
          : trimmedLocale
      })
    }
  },
  watch: {
    langDefaultText (val) {
      this.langText = val === 'pt' ? 'PT' : 'EN'
      Validator.localize(localStorage.getItem('currentLanguage') === 'pt' ? 'pt_PT' : 'en', localStorage.getItem('currentLanguage') === 'pt' ? pt : en)
    }
  },
  created () {
    var langs = this.getBrowserLocales({ languageCodeOnly: true })

    if (window.localStorage.getItem('currentLanguage')) {
      this.handleLocale(window.localStorage.getItem('currentLanguage'))
    } else {
      this.handleLocale(langs[0])
    }
  },
  // do not forget this section
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss" scoped>
$target: if(true, "&", "");

ul {
  width: 100%;
  list-style-type: none;
  margin-bottom: 0;
}

.container-language{
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-choose{
  width: 25px;
  height: 25px;
  color: #1363a5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.language-choose img {
  width: 100%;
}

.speech-bubble {
  position: absolute;
  background: #ffffff;
  border-radius: .4em;
  display: none;
}

.speech-bubble.show-bobbly {
  width: 100px;
  top: -20px;
  display: block;
}


@media (max-width: 545px) {
  .speech-bubble.show-bobbly {
    top: 20px;
  }
}


.speech-bubble span {
  padding: 0;
  color: #1363a5;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bottom .speech-bubble.show-bobbly {
  width: 100px;
  top: 70px;
  display: block;
}

@media (max-width: 768px) {
  .bottom .speech-bubble.show-bobbly {
    top: 120px;
  }
}

.lang-row{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.lang-row:hover{
  border-radius: 5px;
  cursor: pointer;
}

.lang-row:hover .lang-title{
  color: #0a4779;
}
</style>
