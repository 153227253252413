<template>
  <div>
      <nav class="tabs" :class="wrapperClass" v-if="!tabIcon">
    <button
      class="tabs__item"
      type="button"
      v-for="tab in tabs"
      :ref="tab.value"
      :key="tab.title"
      :class="[
        { 'tabs__item_active' : tab.value === currentTab },
        tab.value === currentTab && tabActiveClass ? tabActiveClass: '',
        tabClass,
      ]"
      :disabled="tab.disabled || false"
      @click="handleClick(tab.value)"
      v-html="tab.title"
    />

    <div
      class="tabs__active-line"
      :class="lineClass"
      :style="{ width: `${activeLineWidth}px`, transform: `translateX(${activeLineOffset}px)` }"
    />
  </nav>
  <nav class="tabs" :class="wrapperClass" v-if="tabIcon">
    <button
      class="tabs__item tabs__item-first"
      type="button"
      ref="cURL"
      :class="[
        { 'tabs__item_active' : currentTab === 'cURL' },
        firsTabElement === 'cURL' && tabActiveClass ? tabActiveClass: '',
        tabClass,
      ]"
      @click="handleClick('cURL')"
      v-html="firsTabElement"
      v-if="firsTabElement"
    />
    <img
      class="tabs__item"
      v-for="tab in tabs"
      :ref="tab.value"
      :key="tab.title"
      :class="[
        { 'tabs__item_active' : tab.value === currentTab },
        tab.value === currentTab && tabActiveClass ? tabActiveClass: '',
        tabClass,
      ]"
      :disabled="tab.disabled || false"
      @click="handleClick(tab.value)"
      :src="require(`../assets/stacks/${tab.title}`)"
    />
  </nav>
  </div>
</template>

<script>
export default {
  name: 'vpos-tab',
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    tabIcon: {
      type: Boolean,
      default: false
    },
    updated: {
      type: [Boolean, String, Array],
      default: undefined,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    tabClass: {
      type: String,
      required: false,
    },
    tabActiveClass: {
      type: String,
      required: false,
    },
    lineClass: {
      type: String,
      required: false,
    },
    firsTabElement: {
      type: String,
      required: false,
      default: null
    },
  },
  watch: {
    currentTab(newCurrentTab) {
      if (this.newTab === newCurrentTab) return;
      this.moveActiveLine(newCurrentTab);
      this.newTab = newCurrentTab;
    },
    updated() {
      this.moveActiveLine(this.currentTab);
    },
  },
  data: () => ({
    activeLineWidth: 0,
    activeLineOffset: 0,
    newTab: '',
  }),
  methods: {
    handleClick(value) {
      this.$emit('onClick', value);
      this.moveActiveLine(value);
      this.newTab = value;
    },
    moveActiveLine(newValue) {
      if (!this.currentTab) return;
      if (!this.$refs || !this.$refs[newValue] || !this.$refs[newValue][0]) return;
      const element = this.$refs[newValue][0];
      this.activeLineWidth = element.clientWidth;
      this.activeLineOffset = element.offsetLeft;
    },
  },
  mounted() {
    this.moveActiveLine(this.currentTab);
    this.newTab = this.currentTab;
  },
};
</script>
