<template>
    <footer class="footer">
      <div class="footer_corp">
        <p class="footer_desc">
          © {{new Date().getFullYear()}} Positiva GPO - {{ $t('footer.allrights') }}
        </p>
      </div>
      <div class="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-center align-items-md-center m-4">
        <p class="footer_desc mt-2 mt-md-auto mr-md-2">comercial@positiva.co.ao</p>
        <div class="d-flex flex-row justify-content-center align-items-center">
          <a class="mr-2" href="http://" target="_blank" rel="noopener noreferrer"
            ><img src="../assets/facebook.svg" alt="Facebook"
          /></a>
          <a href="https://twitter.com/vpos_ao" target="_blank" rel="noopener noreferrer"
            ><img src="../assets/twitter.svg" alt="Twitter"
          /></a>
          <menu-language classText="mr-5" v-if="lang"></menu-language>
        </div>
      </div>
    </footer>
</template>
<script>
import MenuLanguage from '@/components/MenuLanguage.vue'

export default {
  components: {
    'menu-language': MenuLanguage
  },
  props: {
    lang: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
/**Footer */
.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    background: #1363a5 0% 0% no-repeat padding-box;
    position: relative;
}
.footer_corp {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer_desc {
    color: #ffffff;
    opacity: 1;
    margin: 0px;
}

@media (min-width: 768px) {
    .footer {
        bottom: 0;
    }
}
@media (max-width: 700px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }
    .footer_desc,
    .footer_desc_first {
        text-align: center;
    }
}
</style>