export default {
  pt: {
    login: {
      btnLogin: 'Entrar',
      recoveryPassword: 'Recuperar Senha'
    },
    header: {
      hours: {
        hour: 'Horário: 07h às 22h',
        callcenter: 'Apoio ao Cliente: +244 938 842 435 | gpo@positiva.co.ao'
      },
      home: 'Pagina Inicial',
      dev: 'Desenvolvedores',
      prices: 'Preçário',
      signin: 'Entrar',
      signup: 'Registar'
    },
    carrousel: {
      firstPhrase: 'Reinventámos a forma de aceitar pagamentos na rede Multicaixa',
      secondPhrase: 'Ofereça segurança e saúde aos seus clientes',
      thirdPhrase: 'Uma melhor forma de aceitar pagamentos',
    },
    whatIsVpos: {
      whatIs: 'O que é Positiva GPO ?',
      quote: 'É uma solução de recebimento fácil e seguro, adaptado ao período COVID-19.',
      receivePayment: 'Pode receber pagamentos <br> com a nossa App'
    },
    merchant: {
      merchant: 'Comerciante',
      description: 'Aceite pagamentos de maneira fácil e segura usando um smartphone.',
      icons: {
        firstIcon: 'Registe o seu négocio',
        secondIcon: 'Escolha o seu Banco',
        thirdIcon: 'E receba Pagamentos',
        btn: 'Registe o seu négocio'
      }
    },
    acceptPayment: 'Aceitamos pagamentos via',
    footer: {
      allrights: 'Todos os direitos reservados'
    },
    integrations: {
      text: 'Ou pode integrar a sua App ou Website <br> usando a nossa API REST ou Bibliotecas e Plugins',
      btn: 'Mais detalhes'
    },
    prices: {
      title: 'Escolha o plano certo para o seu negócio',
      subtitle: 'Saiba sempre quanto vai pagar',
      plans: {
        startup: {
          title: 'Para aqueles que precisam começar',
          subscription: 'Subscrição',
          commission: 'Comissão de Processamento',
          btnText: 'Subscreva'
        },
        business: {
          title: 'Para aqueles que já têm um negócio activo',
          subscription: 'Subscrição',
          commission: 'Comissão de Processamento',
          btnText: 'Subscreva'
        },
        premium: {
          title: 'Para aqueles que têm grande volume de transações',
          subscription: 'Subscrição',
          value: 'Por consulta',
          commission: 'Comissões negociadas',
          btnText: 'Contacte vendas'
        }
      }
    },
    moreInfo: {
      title: 'Mais informações',
      header: {
        subscription: 'Subscrição',
        comission: 'Comissão de Processamento',
        merchant: 'Portal do Vendedor',
        application: 'Aplicação Móvel POS',
        webService: 'Serviços Web',
        support: 'Suporte',
        notes: 'NOTAS'
      },
      startup: {
        comission: '0% até 999.999 AOA / mês',
        merchant: '1 Administrador',
        mobileApp: 'Limitado a um utilizador',
        webService: '',
        support: '9:00 às 17:00 via ticket <br> Até 2 tickets / mês',
        notes: 'A partir de <b>1.000.000 AOA / mês</b> de Volume transacionado a comissão de processamento é de <b>2% + 68 AOA</b> por transacção adicional com cobrança mensal pós-pago.',
        bitNote: `<p>Os preços apresentados são exclusivamente referentes à utilização do Positiva GPO como solução tecnológica de integração com a EMIS.</p>
        <p>O serviço será facturado no início de cada mês incluindo a subscrição desse mês (Pré-pago) e o total das comissões de processamento do mês anterior (Pós-pago).</p>
        <p>Os valores acima expressos, não incluem o serviço bancário que deverá ser contratado separadamente com o banco de apoio de sua preferência.</p>
        <p>Em conformidade com a lei vigente em matéria de preços, agências reguladoras correlatas, as tarifas serão revistas e actualizadas tomando como referência a inflação publicada pelo Instituto Nacional de Estatística.</p>`
      },
      business: {
        merchant: '1 Administrador',
        mobileApp: '1 Utilizador <br> + 6.000 AOA / mês / utilizador adicional',
        webService: 'App ou Website',
        support: '8:00 às 20:00 via Email/Telefone',
      },
      premium: {
        subscription: 'Preço por consulta',
        comission: 'Comissões negociadas',
        merchant: 'Multiplos Administradores',
        mobileApp: 'Preço por consulta',
        webService: 'App ou Website',
        support: '24/7 via Email/Telefone',
        notes: `Volumes transacionados superiores a <b>15.000.000 AOA</b> / mês <br><br>
        Reservamo-nos a o direito de efectuar o downgrade do plano após 3 meses consecutivos de não cumprimento de volumes transacionados.`
      }
    },
    calculators: {
      title: 'Faça as contas',
      substitle: 'Veja quanto custa usar o Positiva GPO. Insira um valor na calculadora para ver nossas cobranças.',
      calculator: {
        label: 'SE O SEU CLIENTE PAGAR',
        fees: 'NOSSOS HONORÁRIOS',
        saleOff: 'Liquidação automática em 24 horas',
        tax: 'Não inclui taxas do seu banco de apoio'
      }
    },
    signup: {
      form: {
        title: 'Registo',
        businessName: 'Denominação do Negócio',
        telephone: 'Telefone',
        btn: 'Regista o seu Negócio'
      },
      alert: {
        nifPickedError: 'NIF já existe',
        emailPickedError: 'Email  já existe',
        namePickedError: 'Nome já existe',
        phonePickedError: 'Telefone já existe',
        defaultErrorMessage: 'Registo não efectuado. Tente novamente',
        sucess: 'O seu registo foi feito com sucesso. Enviamos um email e uma mensagem de texto para o seu telemóvel com os dados de acesso.'
      },
      backBtn: 'Voltar'
    },
    mobileApps: {
      en: 'no'
    }
  }
}
