var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.tabIcon)?_c('nav',{staticClass:"tabs",class:_vm.wrapperClass},[_vm._l((_vm.tabs),function(tab){return _c('button',{key:tab.title,ref:tab.value,refInFor:true,staticClass:"tabs__item",class:[
      { 'tabs__item_active' : tab.value === _vm.currentTab },
      tab.value === _vm.currentTab && _vm.tabActiveClass ? _vm.tabActiveClass: '',
      _vm.tabClass ],attrs:{"type":"button","disabled":tab.disabled || false},domProps:{"innerHTML":_vm._s(tab.title)},on:{"click":function($event){return _vm.handleClick(tab.value)}}})}),_c('div',{staticClass:"tabs__active-line",class:_vm.lineClass,style:({ width: (_vm.activeLineWidth + "px"), transform: ("translateX(" + _vm.activeLineOffset + "px)") })})],2):_vm._e(),(_vm.tabIcon)?_c('nav',{staticClass:"tabs",class:_vm.wrapperClass},[(_vm.firsTabElement)?_c('button',{ref:"cURL",staticClass:"tabs__item tabs__item-first",class:[
      { 'tabs__item_active' : _vm.currentTab === 'cURL' },
      _vm.firsTabElement === 'cURL' && _vm.tabActiveClass ? _vm.tabActiveClass: '',
      _vm.tabClass ],attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.firsTabElement)},on:{"click":function($event){return _vm.handleClick('cURL')}}}):_vm._e(),_vm._l((_vm.tabs),function(tab){return _c('img',{key:tab.title,ref:tab.value,refInFor:true,staticClass:"tabs__item",class:[
      { 'tabs__item_active' : tab.value === _vm.currentTab },
      tab.value === _vm.currentTab && _vm.tabActiveClass ? _vm.tabActiveClass: '',
      _vm.tabClass ],attrs:{"disabled":tab.disabled || false,"src":require(("../assets/stacks/" + (tab.title)))},on:{"click":function($event){return _vm.handleClick(tab.value)}}})})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }