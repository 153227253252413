<template>
  <div class="container_vpos_register">
    <vpos-header :signup="false" bgr='light' :lang="false"/>
    <div class="section_form">
      <div class="form_register" v-if="!isCreated">
        <h4>{{ $t('signup.form.title') }}</h4>
        <!--Nome-->
        <div class="field">
          <label class="label"
            ><span>{{ $t('signup.form.businessName') }}</span>
            <span v-if="namePickedError" class="error-label">{{
              namePickedError
            }}</span></label
          >
          <input
            class="input"
            type="text"
            name="form.name"
            v-model="form.name"
            v-validate="'required'"
            minlength="3"
            data-vv-as="Nome"
            :class="{ input_error: errors.has('form.name') }"
            :disabled="isRequesting"
            placeholder="BOX"
          />
          <div class="error-box">
            <span class="form_error">{{ errors.first("form.name") }}</span>
          </div>
        </div>
        <!--NIF-->
        <div class="field">
          <label class="label"
            ><span>NIF</span>
            <span v-if="nifPickedError" class="error-label">{{
              nifPickedError
            }}</span></label
          >
          <input
            class="input"
            type="text"
            name="form.nif"
            v-validate="'required|min:10|max:14'"
            maxlength="14"
            minlength="10"
            data-vv-as="NIF"
            :disabled="isRequesting"
            :value="form.nif"
            :class="{ input_error: errors.has('form.nif') }"
            @input="(evt) => (form.nif = evt.target.value)"
            autocomplete="off"
            placeholder="0532485943LA5"
          />
          <div class="error-box">
            <span class="form_error">{{ errors.first("form.nif") }}</span>
          </div>
        </div>
        <!--Email-->
        <div class="field">
          <label class="label"
            ><span>Email</span>
            <span v-if="emailPickedError" class="error-label">{{
              emailPickedError
            }}</span></label
          >
          <input
            class="input"
            type="email"
            name="form.email"
            v-model="form.email"
            v-validate="'required'"
            data-vv-as="Email"
            :disabled="isRequesting"
            :class="{ input_error: errors.has('form.email') }"
            placeholder="email@example.com"
          />
          <div class="error-box">
            <span class="form_error">{{ errors.first("form.email") }}</span>
          </div>
        </div>
        <!--Telefone-->
        <div class="field">
          <label class="label"
            ><span>{{ $t('signup.form.telephone') }}</span>
            <span v-if="phonePickedError" class="error-label">{{
              phonePickedError
            }}</span></label
          >
          <div class="form-group">
            <span class="input-box-flag"
              ><img src="../assets/ao-flag.png" alt=""
            /></span>
            <input
              class="form-field"
              type="text"
              v-model="phoneNumber"
              placeholder="9XX XXX XXX"
              v-mask="'### ### ###'"
            />
          </div>
        </div>
        <div class="field">
          <button @click="ValidateForm" class="button_submit">
            <span class="btn-text" v-if="!isRequesting">
              {{ $t('signup.form.btn') }}</span
            >
            <div class="loading-dots" v-if="isRequesting">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </button>
        </div>
      </div>
      <div class="sucess-message" v-else>
        <h3>{{ $t('signup.form.title') }}</h3>
        <img src="../assets/success.svg" alt="" />
        <p>{{ $t('signup.alert.sucess') }}</p>
        <button @click="goBack" class="button_submit">
          {{ $t('signup.backBtn') }}
        </button>
      </div>
    </div>
    <vpos-footer :lang="true"/>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import vPosHeader from '@/components/vPosHeader.vue'
import vPosFooter from '@/components/vPosFooter.vue'

export default {
  name: "CreateAccount",
  directives: { mask },
  components: {
    'vpos-header': vPosHeader,
    'vpos-footer': vPosFooter
  },
  data() {
    return {
      form: {
        name: "",
        nif: "",
        email: "",
        phone_number: "",
      },
      isRequesting: false,
      isCreated: false,
      hadError: "",
      hadSuccess: "",
      phoneNumber: "",
      nifPickedError: "",
      namePickedError: "",
      phonePickedError: "",
      emailPickedError: "",
    };
  },
  methods: {
    /*
     *  ProcessForm: This method will validate the form using v-validate
     *  component and then call the action method defined for this view
     *  if everything passes the validation.
     */
    async ValidateForm() {
      this.hadError = "";
      this.clearError();
      const result = await this.$validator.validateAll();
      return result ? this.CreateAccount() : result;
    },
    /*
     *  CreateAccount: This method will request the api using a POST method
     *  to create a new user account.
     */
    async CreateAccount() {
      this.isRequesting = true;
      this.form.phone_number = `+244${this.myTrim(this.phoneNumber)}`;
      try {
        await this.axios.post(`/register`, this.form);
        this.isCreated = true;
      } catch (error) {
        // Handle errors
        this.CreateAccountError(error);
      }
      this.isRequesting = false;
    },
    /**
     * CreateAccountError: This method will handle all the errors related
     * to the signup process.
     *
     * @param {object} error
     * */
    CreateAccountError(error) {
      const defaultErrorMessage = this.$t('signup.alert.defaultErrorMessage');
      if (error.response) {
        switch (error.response.data[0].code) {
          case "MERCHANT_WITH_NIF_EXIST":
            this.nifPickedError =  this.$t('signup.alert.nifPickedError');
            this.hadError = "error";
            break;
          case "MERCHANT_WITH_EMAIL_EXIST":
            this.emailPickedError = this.$t('signup.alert.emailPickedError');
            this.hadError = "error";
            break;
          case "MERCHANT_WITH_NAME_EXIST":
            this.namePickedError = this.$t('signup.alert.namePickedError');
            this.hadError = "error";
            break;
          case "MERCHANT_WITH_PHONE_NUMBER_EXIST":
            this.phonePickedError = this.$t('signup.alert.phonePickedError');
            this.hadError = "error";
            break;
          default:
            this.hadError = defaultErrorMessage;
            break;
        }
      } else {
        this.hadError = defaultErrorMessage;
      }
    },
    myTrim(x) {
      return x.replace(/[^\d]/g, "");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      this.isCreated = false;
    },
    clearError() {
      this.nifPickedError = "";
      this.namePickedError = "";
      this.emailPickedError = "";
      this.phonePickedError = "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn_entrar {
  background: #d05d1c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border: none;
  padding: 9px 35px;
  color: #ffffff;
}
.btn_letter {
  color: #ffffff;
}
a button {
  cursor: pointer;
}

/*FORMULARIO*/
.section_form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 200px 0px;
}
.form_register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  max-height: 700px;
  padding: 30px 22px;
  background: #e7eff6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid #e7eff6;
}
h4 {
  color: #1363a5;
  font-weight: bold;
  text-align: center;
}
.form_error {
  color: #f00 !important;
  font-size: 12px;
}
.field {
  width: 100%;
  margin-top: 10px;
}
.field label {
  display: block;
  width: 100%;
  color: #1363a5;
}

.field label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.field label .error-label {
  text-align: end;
  color: #be1212 !important;
}
input[type="text"],
input[type="email"] {
  width: 100%;
  padding-left: 10px;
  height: 48px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 10px;
  border: 2px solid #8cbee8;
  background: #ffffff 0% 0% no-repeat padding-box;
  outline: none;
  opacity: 1;
}
.button_submit {
  display: block;
  background-color: #1363a5;
  width: 340px;
  height: 45px;
  border: none;
  outline: 0;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  margin-top: 20px;
}

.field .btn-text {
  display: block;
  width: 100%;
  color: #fff;
}

@media (width: 375px) {
  .formulario {
    padding: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) and (orientation: portrait) {
  .section_form {
    display: flex;
  }
  input[type="submit"] {
    width: 250px;
    height: 50px;
  }
  div.section_form {
    margin: 5px 0px;
  }
}
@media (width: 360px) {
  div.section_form {
    margin: 5px 0px;
  }
  .form_register {
    padding: 4px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 500px) and (orientation: portrait) {
  div.section_form {
    margin: 7px 0px !important;
  }
  .form_register {
    padding: 10px;
  }
}

/* Message to Success & Error*/
.sucess-message {
  width: 424px;
  height: auto;
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #e7eff6 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.sucess-message h3 {
  font-size: 32px;
  font-weight: bold;
  color: #1363a5;
  text-align: center;
}

.sucess-message img {
  margin-top: 22px;
  margin-bottom: 8px;
}

.sucess-message p {
  font-size: 18px;
  font-weight: bold;
  color: #1363a5;
  text-align: center;
}

.error_box {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error_box img {
  margin-top: 22px;
  margin-bottom: 8px;
}

.error_box h3 {
  font-size: 20px;
  font-weight: bold;
  color: #1363a5;
  text-align: center;
}

@media screen and (max-width: 425px) {
  .form_register {
    width: 100%;
    margin: 20px;
  }

  .sucess-message {
    width: 100%;
    height: 400px;
    margin: 20px;
  }

  .button_submit {
    width: 100%;
  }

  div.section_form {
    height: 100vh;
  }
}

@media screen and (max-width: 320px) {
  div.section_form {
    height: 120vh;
  }
}

/** Input For Phone Number */

.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 10px;
  -webkit-appearance: none;
  /* color: #1363A5; */
  background: #e7eff6;
  border: 1px solid #8cbee8;
  border-left: none;
  transition: border 0.3s ease;
}

.form-field:focus {
  outline: none;
}

.form-group {
  position: relative;
  display: flex;
  width: 100%;
}

.form-group > span,
.form-group .form-field {
  white-space: nowrap;
  display: block;
}

.form-group > span:not(:first-child):not(:last-child),
.form-group .form-field:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.form-group > span:first-child,
.form-group .form-field:first-child {
  border-radius: 10px 0 0 10px;
}

.form-group > span:last-child,
.form-group .form-field:last-child {
  border-radius: 0 10px 10px 0;
}

.form-group > span:not(:first-child),
.form-group .form-field:not(:first-child) {
  margin-left: -1px;
}

.form-group .form-field {
  position: relative;
  z-index: 0;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}

.form-group > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: #1363a5;
  /* background: #e7eff6; */
  background: #fff;
  border: 2px solid #8cbee8;
  border-right: none;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.form-group {
  max-width: 360px;
}

.form-group:not(:last-child) {
  margin-bottom: 32px;
}

.input-box-flag {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>
