export default {
  en: {
    login: {
      btnLogin: 'Login',
      recoveryPassword: 'Recover Password'
    },
    header: {
      hours: {
        hour: 'Hours: 07AM to 10PM',
        callcenter: 'Call Center: +244 938 842 435 | gpo@positiva.co.ao'
      },
      home: 'Home',
      dev: 'Developers',
      prices: 'Pricing',
      signin: 'Login',
      signup: 'Signup'
    },
    carrousel: {
      firstPhrase: 'We have reinvented how to accept payments on the Multicaixa network',
      secondPhrase: 'Provide a safe and healthy experience to your customers',
      thirdPhrase: 'A better way to accept payments',
    },
    whatIsVpos: {
      whatIs: 'What is Positiva GPO?',
      quote: 'An easy and secure payment gateway adapted to the covid-19 period.',
      receivePayment: 'Accept payments via <br> our App'
    },
    merchant: {
      merchant: 'Merchant',
      description: 'Accept payments easily and securely using any smartphone.',
      icons: {
        firstIcon: 'Register your business',
        secondIcon: 'Choose your Bank',
        thirdIcon: 'And get paid',
        btn: 'Register your business'
      }
    },
    acceptPayment: 'We accept payment via',
    footer: {
      allrights: 'All rights reserved'
    },
    integrations: {
      text: 'or Integrate your App or Website <br> using our REST API or Libraries and Plugins',
      btn: 'More details'
    },
    prices: {
      title: 'Choose the right plan for your business',
      subtitle: 'Always know how much you will pay',
      plans: {
        startup: {
          title: 'For those who needs to start',
          subscription: 'Subscription',
          commission: 'Processing Fee',
          btnText: 'Subscribe'
        },
        business: {
          title: 'For those who already have an active business',
          subscription: 'Subscription',
          commission: 'Processing Fee',
          btnText: 'Subscribe'
        },
        premium: {
          title: 'For those who have a high volume of transactions',
          subscription: 'Subscription',
          value: 'By consultation',
          commission: 'By consultation',
          btnText: 'Contact Sales'
        }
      }
    },
    moreInfo: {
      title: 'More Info',
      header: {
        subscription: 'Subscription',
        comission: 'Processing Fee',
        merchant: 'Merchant Portal',
        application: 'Mobile APP POS',
        webService: 'Web Service',
        support: 'Support',
        notes: 'NOTE'
      },
      startup: {
        comission: '0% up to AOA 999,999 / month',
        merchant: '1 Administrator',
        mobileApp: 'Limited to one user',
        webService: '',
        support: '9:00 am to 5:00 pm via ticket <br> Up to 2 tickets / month',
        notes: 'From <b> 1,000,000 AOA / month </b> of Volume transacted, the processing fee is <b>2% + 68 AOA</b> per additional transaction with monthly postpaid charge.',
        bitNote: `<p> The prices shown are exclusively for the use of Positiva GPO as a technological solution for integration with EMIS. </p>
        <p> The service will be invoiced at the beginning of each month including the subscription for that month (Prepaid) and the total processing fees from the previous month (Postpaid). </p>
        <p> The above values do not include the banking service that must be contracted separately with the support bank of your choice. </p>
        <p> In accordance with the current law on prices, related regulatory agencies, the tariffs will be revised and updated taking as reference the inflation published by the National Statistics Institute. </p>`
      },
      business: {
        merchant: '1 Administrator',
        mobileApp: '1 User <br> + 6,000 AOA / month / additional user',
        webService: 'App or Website',
        support: '8:00 to 20:00 via Email/Telefone',
      },
      premium: {
        subscription: 'Price per consultation',
        comission: 'By consultation',
        merchant: 'Multiple Administrators',
        mobileApp: 'Price per consultation',
        webService: 'App or Website',
        support: '24/7 via Email/Phone',
        notes: `Transacted volumes greater than <b> AOA 15,000,000 </b> / month <br> <br>
        We reserve the right to downgrade the plan after 3 consecutive months of non-compliance with the volumes transacted.`
      }
    },
    calculators: {
      title: 'Do the math',
      substitle: 'See how much it costs to use Positiva GPO. Enter a value in the calculator to see our charges.',
      calculator: {
        label: 'IF YOUR CUSTOMER PAYS',
        fees: 'OUR FEES',
        saleOff: 'Automatic settlement in 24 hours',
        tax: 'Does not include fees from your support bank'
      }
    },
    signup: {
      form: {
        title: 'Signup',
        businessName: 'Business Name',
        telephone: 'Telephone',
        btn: 'Register your business'
      },
      alert: {
        nifPickedError: 'NIF already exists',
        emailPickedError: 'Email already exists',
        namePickedError: 'Name already exists',
        phonePickedError: 'Telephone already exists',
        defaultErrorMessage: 'Registration not successful. Try again',
        sucess: 'Your registration was successful. We send an email and a text message to your mobile phone with the access data.'
      },
      backBtn: 'Back'
    },
    mobileApps: {
      en: 'yes'
    }
  }
}
