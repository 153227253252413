<template>
  <div class="container-vpos">
    <section class="prices-info">
      <div class="inside_vpos">
        <vpos-header :pricing="false" bgr='light'/>
        <!--Carousel Fade start-->
        <div class="price-text-center">
          <div class="price-text-element">
            <h1>{{ $t('prices.title') }}</h1>
            <span>{{ $t('prices.subtitle') }}</span>
          </div>
        </div>
        <!--Crousel Fade end-->
      </div>
    </section>
    <section class="prices-plan">
      <div class="plans">
        <div class="box-plans left">
          <span class="plan-type">Startup</span>
          <span class="plan-desc">{{ $t('prices.plans.startup.title') }}</span>
          <div class="plan-subscription">
            <span class="plan-subscription-title">{{ $t('prices.plans.startup.subscription') }}</span>
            <span class="plan-price">7.000 AOA</span>
          </div>
          <span class="plan-comission-title">{{ $t('prices.plans.startup.commission') }}</span>
          <span class="plan-comission-percentage">0%*</span>
          <router-link class="plan-btn" to="">
            <button>{{ $t('prices.plans.startup.btnText') }}</button>
          </router-link>
        </div>
        <div class="box-plans center">
          <span class="plan-type">Business</span>
          <span class="plan-desc">{{ $t('prices.plans.business.title') }}</span>
          <div class="plan-subscription">
            <span class="plan-subscription-title">{{ $t('prices.plans.business.subscription') }}</span>
            <span class="plan-price">35.000 AOA</span>
          </div>
          <span class="plan-comission-title">{{ $t('prices.plans.business.commission') }}</span>
          <span class="plan-comission-percentage">0,5% + 68 AOA</span>
          <router-link class="plan-btn" to="">
            <button>{{ $t('prices.plans.business.btnText') }}</button>
          </router-link>
        </div>
        <div class="box-plans right">
          <span class="plan-type">Premium</span>
          <span class="plan-desc">{{ $t('prices.plans.premium.title') }}</span>
          <div class="plan-subscription">
            <span class="plan-subscription-title">{{ $t('prices.plans.premium.subscription') }}</span>
            <span class="plan-price">{{ $t('prices.plans.premium.value') }}</span>
          </div>
          <span class="plan-comission-title">{{ $t('prices.plans.premium.commission') }}</span>
          <a href="mailto:comercial@positiva.co.ao" class="plan-btn" style="cursor: pointer;">
            <button>{{ $t('prices.plans.premium.btnText') }}</button>
          </a>
        </div>
      </div>
    </section>
    <section class="prices-more-info">
      <div class="prices-more-info-center">
        <div class="prices-more-info-title">
          <div class="info-title-header">
            {{ $t('moreInfo.title') }}
          </div>
          <div class="info-title-body">
            <span>{{ $t('moreInfo.header.subscription') }}</span>
            <span>{{ $t('moreInfo.header.comission') }}</span>
            <span>{{ $t('moreInfo.header.merchant') }}</span>
            <span>{{ $t('moreInfo.header.application') }}</span>
            <span>{{ $t('moreInfo.header.webService') }}</span>
            <span>{{ $t('moreInfo.header.support') }}</span>
            <span class="note">{{ $t('moreInfo.header.notes') }}</span>
          </div>
        </div>
       <div class="prices-plans d-flex flex-column w-100 position-relative">
         <div class="prices-plan__box d-flex flex-column flex-lg-row h-100">
            <div class="prices-more-info-startup">
              <div class="info-startup-header">
                Startup
              </div>
              <div class="info-startup-body">
                <span>7.000 AOA</span>
                <span>{{ $t('moreInfo.startup.comission') }}</span>
                <span>{{ $t('moreInfo.startup.merchant') }}</span>
                <span>{{ $t('moreInfo.startup.mobileApp') }}</span>
                <span>-</span>
                <span v-html="$t('moreInfo.startup.support')"></span>
                <div class="note-box" v-html="$t('moreInfo.startup.notes')"></div>
              </div>
            </div>
            <div class="prices-more-info-business">
              <div class="info-business-header">
                Business
              </div>
              <div class="info-business-body">
                <span>35.000 AOA</span>
                <span>0,5% + 68 AOA</span>
                <span>{{ $t('moreInfo.business.merchant') }}</span>
                <span v-html="$t('moreInfo.business.mobileApp')"></span>
                <span>{{ $t('moreInfo.business.webService') }}</span>
                <span>{{ $t('moreInfo.business.support') }}</span>
              </div>
            </div>
            <div class="prices-more-info-premium">
              <div class="info-premium-header">
                Premium
              </div>
              <div class="info-premium-body">
                <span>{{ $t('moreInfo.premium.subscription') }}</span>
                <span>{{ $t('moreInfo.premium.comission') }}</span>
                <span>{{ $t('moreInfo.premium.merchant') }}</span>
                <span>{{ $t('moreInfo.premium.mobileApp') }}</span>
                <span>{{ $t('moreInfo.premium.webService') }}</span>
                <span>{{ $t('moreInfo.premium.support') }}</span>
                <div v-html="$t('moreInfo.premium.notes')" class="note-box"></div>
              </div>
            </div>
            <div class="supreme-note-box position-absolute" v-html="$t('moreInfo.startup.bitNote')"></div>
         </div>
       </div>
       <div class="supreme-note-box-mb" v-html="$t('moreInfo.startup.bitNote')"></div>
      </div>
    </section>
    <section class="prices-calculators">
        <div class="prices-calculators-left">
          <h2>{{ $t('calculators.title') }}</h2>
          <span>{{ $t('calculators.substitle') }}</span>
        </div>
        <div class="prices-calculators-right">
          <div class="price-calculator">
            <div class="price-client-value">
              <label for="">{{ $t('calculators.calculator.label') }}</label>
              <div>
                <span class="icon-text"></span>
                <money v-model="value" v-bind="money"></money>
              </div>
            </div>
            <div class="price-client-startup-value">
              <span class="client-startup-title">Startup</span>
              <span class="client-startup-fees">{{ $t('calculators.calculator.fees') }} (0%)*</span>
              <span class="client-startup-values">{{ startupValues }}</span>
            </div>
            <div class="price-client-business-value">
              <span class="client-business-title">Business</span>
              <span class="client-business-fees">{{ $t('calculators.calculator.fees') }} (0.5%) + 68AOA</span>
              <span class="client-business-values">{{ businessValues }}</span>
            </div>
            <div class="price-client-more">
              <span>{{ $t('calculators.calculator.saleOff') }} <img class="icon-resize" src="../assets/icons/check.png" alt=""></span>
              <span>{{ $t('calculators.calculator.tax') }} <img class="icon-resize" src="../assets/icons/x-mark.png" alt=""></span>
            </div>
          </div>
        </div>
    </section>
    <vpos-footer/>
  </div>
</template>

<script>
import vPosHeader from '@/components/vPosHeader.vue'
import vPosFooter from '@/components/vPosFooter.vue'

export default {
  components: {
    'vpos-header': vPosHeader,
    'vpos-footer': vPosFooter
  },
  data() {
    return {
      value: 0,
      price: 123.45,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'AOA  ',
        suffix: ' ',
        precision: 2,
        masked: false
      },
      startupValue: 0,
      businessValue: 0
      
    }
  },
  computed: {
    startupValues () {
      let newValue = new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'AOA' }).format(this.startupValue.toFixed(2))
      return newValue
    },
    businessValues () {
      let newValue = new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'AOA' }).format(this.businessValue.toFixed(2))
      return newValue
    } 
  },
  watch: {
    value (val) {
      this.startupValue = val ? (val >= 1000000 ? (parseFloat(val) * 1)/100 + 68: parseFloat(val) * 0) : 0
      this.businessValue = val ? ((parseFloat(val) * 0.5)/100 + 68) : 0
    }
  },
  methods: {
    currencyFormat(val) {
      return new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'AOA' }).format(val.toFixed(2))
    },
    formatValue() {
      this.value = this.currencyFormat(this.value)
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prices-info {
  display: flex;
  flex-direction: column;
  position: relative;
}
.prices-info::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 711px;
  position: absolute;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 80%,
              rgba(255,255,255,1)), url(../assets/backgrounds/prices-wallpapers.png);
  background-size: cover;
  z-index: -2;
}
.inside_vpos {
  position: relative;
}

.btn_letter {
  color: #ffffff;
  text-decoration: none;
}
a button {
  cursor: pointer;
}

.price-text-center{
  margin-top: 120px;
}

.price-text-element{
  width: 508px;
  height: 127px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price-text-element h1{
  text-align: center;
  letter-spacing: 1.56px;
  color: #D05D1C;
  font-size: 46px;
}

.price-text-element span{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.24px;
  color: #5A5A5A;
  opacity: 1;
}
@media (max-width: 700px) {
  .price-text-element {
    margin: 5px 0px !important;
  }
  h1 {
    font-size: 1.2em;
    font-weight: bold;
  }
}
@media (max-width: 446px) {
  .price-text-element {
    margin: 5px 15px !important;
  }

  .price-text-element h1{
    text-align: center;
    letter-spacing: 1.56px;
    color: #D05D1C;
    font-size: 28px;
  }
}
@media (max-width: 320px) {
  .price-text-element {
    margin: 5px 0px !important;
  }
  h1 {
    font-size: 1em;
    font-weight: bold;
  }
}
@media (width: 360px) {
  .price-text-element {
    margin: 0px !important;
  }
}
/**CAROUSEL */
.price-text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/**menu lateral end */

.plans{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 482px;
  z-index: 99;
}

.box-plans{
  width: 427px;
  height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 14px #0000003C;
  border-radius: 8px;
  opacity: 1;
  padding: 22px;
}

.box-plans.center{
  height: 483px;
  justify-content: space-between;
}

.box-plans.left,
.box-plans.right{
  z-index: -1;
  display: flex;
}

.box-plans.left{
  margin-right: -10px;
}

.box-plans.right{
  background: #1363A5 0% 0% no-repeat padding-box;
  margin-left: -10px;
  justify-content: space-between;
}

.prices-plan{
  height: 700px;
  display: flex;
  top: 482px;
}

@media (max-width: 568px) {
  .plans{
    position: inherit;
    padding-top: 75px;
  }

  .prices-plan{
    height: 1600px;
    padding: 25px;
  }
}

.plan-type{
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1363A5;
  opacity: 1;
  margin-bottom: 16px;
}

.plan-desc{
  font-size: 19px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-bottom: 25px;
  text-align: center;
}

.plan-subscription{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 21px;
}

.plan-comission-title,
.plan-subscription-title{
  font-size: 24px;
  letter-spacing: 0px;
  color: #5A5A5A;
  text-align: center;
}

.plan-comission-percentage,
.plan-price{
  font-size: 32px;
  letter-spacing: 0px;
  color: #5A5A5A;
}

.box-plans.right .plan-type,
.box-plans.right .plan-desc,
.box-plans.right .plan-comission-title,
.box-plans.right .plan-subscription-title,
.box-plans.right .plan-comission-percentage,
.box-plans.right .plan-price{
  color: #FFFFFF;
}

.plan-btn{
  width: 100%;
  margin-top: 20px;
}

.plan-btn button{
  width: 100%;
  height: 70px;
  background: #1363A5 0% 0% no-repeat padding-box;
  border: #1363A5;
  color: #FFFFFF;
  border-radius: 8px;
  opacity: 1;
}

.box-plans.right .plan-btn button{
  background: #0A3A62;
  border: #0A3A62;
  color: #FFFFFF;
}

.prices-more-info{
  width: 100%;
  height: 957px;
  /* height: auto; */
  display: flex;
  flex-wrap: nowrap;
  padding-top: 50px;
}

.prices-more-info-center{
  width: 100%;
  height: auto;
  background-color: #e7eff6;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 100px;
  justify-content: flex-end;
}

.prices-more-info-title{
  width: 35%;
  display: flex;
  flex-direction: column;
  background-color: #e7eff6;
}

/* .prices-plans{
  width: 75%;
} */

.info-title-header{
  height: 100px;
  border-bottom: 1px solid #b3b3b3;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #1363A5;
  opacity: 1;
  padding-left: 4px;
}

.info-title-body{
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

.info-title-body span{
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
  margin-top: 16px;
}

.info-title-body span.note{
  font-size: 24px;
  font-style: italic;
}

.prices-more-info-startup,
.prices-more-info-business,
.prices-more-info-premium{
  width: 34%;
  background-color: #e7eff6;
}

.prices-more-info-premium{
  background-color: #d0e1ed;
  /* padding-right: 100px; */
}

.info-startup-header,
.info-business-header,
.info-premium-header{
  height: 100px;
  border-bottom: 1px solid #b3b3b3;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1363A5;
  opacity: 1;
  padding-left: 4px;
}

.info-startup-body,
.info-business-body,
.info-premium-body{
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

.info-startup-body span,
.info-business-body span,
.info-premium-body span{
  height: 50px;
  font-size: 16px;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
  margin-top: 16px;
}

.info-premium-header,
.info-premium-body{
  padding-left: 38px;
}

.note-box{
  width: 83%;
  margin-top: 16px;
  background: var(--unnamed-color-f1f9ff) 0% 0% no-repeat padding-box;
  background: #F1F9FF 0% 0% no-repeat padding-box;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #5A5A5A;
  opacity: 1;
  font-size: 12px;
}

.supreme-note-box{
  /* width: 1190px; */
  width: 95%;
  /* margin-top: 66px; */
  background: var(--unnamed-color-f1f9ff) 0% 0% no-repeat padding-box;
  background: #F1F9FF 0% 0% no-repeat padding-box;
  padding: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
  font-size: 14px;
  z-index: 2;
  /* margin-bottom: 65px; */
  bottom: 20px;
}

.supreme-note-box-mb{
  display: none;
}

.prices-calculators{
  width: 100%;
  padding: 109px 0;
  display: flex;
  flex-wrap: wrap;
}

.prices-calculators-left{
  width: 50%;
  padding-left: 261px;
  padding-right: 100px;
}

.prices-calculators-left h2{
  letter-spacing: 0px;
  color: #1363A5;
  font-weight: 800;
  opacity: 1;
}

.prices-calculators-left span{
  width: 353px;
  height: 71px;
  text-align: left;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.prices-calculators-right{
  width: 50%;
}

.price-calculator{
  width: 467px;
  height: 451px;
  background: #1363A5 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.price-client-value{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 35px;
}

.price-client-value label{
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #F1F9FF;
  text-transform: uppercase;
  opacity: 1;
}

.price-client-value span { 
  position: absolute;
  height: 54px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1363A5;
}

.price-client-value input{
  height: 54px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: #1363A5;
  border-radius: 4px;
  opacity: 1;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1363A5;
  padding-left: 20px;
}

.price-client-startup-value,
.price-client-business-value{
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.client-startup-title,
.client-business-title{
  font-size: 19px;
  letter-spacing: 0px;
  color: #F1F9FF;
  text-transform: uppercase;
  opacity: 1;
}

.client-startup-fees,
.client-business-fees{
  font-size: 12px;
  letter-spacing: 0px;
  color: #F1F9FF;
  text-transform: uppercase;
  opacity: 1;
}

.client-startup-values,
.client-business-values{
  font-size: 19px;
  letter-spacing: 0px;
  color: #BCE0FD;
  text-transform: uppercase;
  opacity: 1;
}

.price-client-more{
  display: flex;
  flex-direction: column;
}

.price-client-more span{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-size: 16px;
  letter-spacing: 0px;
  color: #BCE0FD;
  opacity: 1;
}

.icon-resize{
  width: 18px;
  height: 16px;
}

@media (max-width: 568px) {
  .box-plans{
    width: 100%;
    height: auto;
  }

  .box-plans.left,
  .box-plans.right{
    margin: 0;
  }

  .prices-more-info{
    padding-left: 0;
    height: 2250px;
  }

  .prices-more-info-center{
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #ffffff;
  }

  .prices-more-info-title{
    display: none;
  }

  .prices-more-info-startup,
  .prices-more-info-business,
  .prices-more-info-premium {
    width: 100%;
    background-color: #e7eff6;
    margin-bottom: 40px;
    padding: 0;
  }

   .info-title-header{
     font-size: 26px;
   }

  .info-title-header,
  .info-startup-header, 
  .info-business-header, 
  .info-premium-header {
    height: 70px;
    padding-left: 38px;
    display: flex;
    align-items: center;
    /* padding: 0; */
  }

  .info-startup-body,
  .info-business-body,
  .info-premium-body {
    padding-left: 38px;
  }

  .note-box{
    margin-bottom: 38px;
  }

  .supreme-note-box{
    display: none;
  }

  .supreme-note-box-mb{
    display: block;
    width: 100%;
    background: #F1F9FF 0% 0% no-repeat padding-box;
    padding: 10px;
    text-align: left;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-size: 14px;
    padding: 30px 15px;
  }

  .prices-calculators{
    padding: 54px 0;
  }

  .price-calculator{
    width: 100%;
  }

  .prices-calculators-left,
  .prices-calculators-right{
    padding: 25px;
    width: 100%;
  }

  .price-client-value input{
    width: 100%;
  }

  .price-client-more span{
    font-size: 13px;
  }
}

@media (min-width: 512px) and  (max-width: 1024px) {
  .prices-plan {
    height: 450px;
  }
  
  .box-plans{
    width: 245px;
    height: 268px;
  }

  .box-plans.center{
    height: 299px;
    justify-content: space-between;
  }

  .plan-type,
  .plan-comission-percentage,
  .plan-price{
    font-size: 19px;
  }

  .plan-desc,
  .plan-subscription-title,
  .plan-comission-title{
    font-size: 14px;
  }
  .plan-desc,
  .plan-type,
  .plan-subscription{
    margin-bottom: 7px;
  }

  .plan-btn button{
    height: 40px;
  }

  .prices-more-info{
    padding-left: 0;
    height: 2250px;
  }

  .prices-more-info-center{
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #ffffff;
  }

  .prices-more-info-title{
    display: none;
  }

  .prices-more-info-startup,
  .prices-more-info-business,
  .prices-more-info-premium {
    width: 100%;
    background-color: #e7eff6;
    margin-bottom: 40px;
    padding: 0;
  }

   .info-title-header{
     font-size: 26px;
   }

  .info-title-header,
  .info-startup-header, 
  .info-business-header, 
  .info-premium-header {
    height: 70px;
    padding-left: 38px;
    display: flex;
    align-items: center;
    /* padding: 0; */
  }

  .info-startup-body,
  .info-business-body,
  .info-premium-body {
    padding-left: 38px;
  }

  .note-box{
    margin-bottom: 38px;
  }

  .supreme-note-box{
    display: none;
  }

  .supreme-note-box-mb{
    display: block;
    width: 100%;
    background: #F1F9FF 0% 0% no-repeat padding-box;
    padding: 10px;
    text-align: left;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-size: 14px;
    padding: 30px 15px;
  }

  .prices-calculators{
    padding: 54px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .price-calculator{
    width: 100%;
  }

  .prices-calculators-left,
  .prices-calculators-right{
    padding: 25px;
    width: 100%;
  }

  .price-client-value input{
    width: 100%;
  }

  .price-client-more span{
    font-size: 13px;
  }
}

@media (min-width: 509px) and  (max-width: 669px) {
  .plans{
    display: flex;
    flex-direction: column;
  }

  .prices-plan {
    height: 1020px;
  }
  .box-plans.left,
  .box-plans.right,
  .box-plans.center{
    width: 345px;
    margin-bottom: 20px;
  }
}

/* @media (min-width: 1025px) and  (max-width: 1440px) {
  .supreme-note-box{
    width: 985px;
  }
} */

</style>
